import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import fetcher_data from "../data_fetcher";
import courier_fetch from "../courier_fetcher";
import broker_fetch from "../broker_fetcher";

export default function Component({ app, setApp, setReloadStuffs }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    let first_api = "";

    if (app === "banking") {
      first_api = fetcher_data.reads[0].path;
    }
    if (app === "courier") {
      first_api = courier_fetch.reads[0].path;
    }
    if (app === "broker") {
      first_api = broker_fetch.reads[0].path;
    }

    toast.loading(`Logging in to ${app}`, {
      theme: "dark",
      position: "bottom-center",
    });

    localStorage.setItem("tenantId", username);
    localStorage.setItem("token", password);

    let get = null;

    if (app === "banking") {
      get = await fetcher_data.getData(first_api, fetcher_data.reads[0].formatter_function);
    }

    if (app === "courier") {
      get = await courier_fetch.getData(first_api, courier_fetch.reads[0].formatter_function);
    }

    if (app === "broker") {
      get = await broker_fetch.getData(first_api, broker_fetch.reads[0].formatter_function);
    }

    if (get === "Unauthorized" || get === "err") {
      setError("Invalid Credentials");
      toast.error("Invalid Credentials Submitted", {
        theme: "dark",
        position: "bottom-center",
      });
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
      return;
    } else {
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
      navigate("/reads");
    }
  };

  let dropdownItems = ["Banking", "Courier", "Broker"];

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="w-full max-w-md bg-gray-800 rounded-xl shadow-lg p-8 transition-all hover:shadow-2xl transform hover:-translate-y-1">
        <h2 className="text-4xl font-bold text-center text-white mb-6">Login</h2>
        <p className="text-center text-gray-400 mb-8">
          Enter your Login Credentials
        </p>

        {/* App Selection Dropdown */}
        <div className="mb-6">
          <label
            htmlFor="app-select"
            className="block text-gray-300 font-medium mb-2"
          >
            Select Application
          </label>
          <select
            id="app-select"
            className="w-full p-3 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 transition-all"
            onChange={(e) => {
              setApp(e.target.value);
              localStorage.setItem("selectedApp", e.target.value);
            }}
            defaultValue={localStorage.getItem("selectedApp") || "banking"}
          >
            {dropdownItems.map((item, i) => (
              <option key={i} value={item.toLowerCase()}>
                {item}
              </option>
            ))}
          </select>
        </div>

        {/* TID Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-300 font-medium mb-2">
            Username
          </label>
          <input
            id="email"
            type="email"
            placeholder="Enter your Username"
            value={username}
            onChange={(e) => {
              setReloadStuffs(Math.random());
              localStorage.setItem("tenantId", e.target.value);
              setUsername(e.target.value);
            }}
            required
            className="w-full p-3 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 transition-all"
          />
        </div>

        {/* Token Input */}
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-300 font-medium mb-2">
            Password
          </label>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full p-3 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 transition-all"
          />
        </div>

        {/* Login Button */}
        <button
          className="w-full py-3 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-500 transition-all duration-200 transform hover:scale-105"
          onClick={handleLogin}
        >
          Login
        </button>

        {/* Error Handling */}
        {error && (
          <div className="mt-4 text-center text-red-500 font-medium">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
