import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Menu,
  Package2,
  X,
  Rocket,
  UserPlus,
  DollarSign,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";

import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import moment from "moment";

const Navbar = ({ app, reloadStuffs }) => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [valid_till, setValidTill] = useState("");
  const [is_valid_tenant, setIsValidTenant] = useState(false);

  // Function to fetch tenant expiry info
  async function getExpiryInfo() {
    const tenantId = localStorage.getItem("tenantId");
    const api = `https://sentinel.solidhash.io/peripheral-license/license/tenant/validity/${tenantId}`;
    try {
      const response = await fetch(api);
      const json = await response.json();

      if (json.url === "s") {
        setIsValidTenant(false);
        return;
      }

      const date = new Date(json.valid_until_timestamp * 1000);
      const daysRemaining = moment(date).diff(moment(), 'days');
      setValidTill(`${daysRemaining} days`);
      setIsValidTenant(true);
    } catch (e) {
      console.error("Error fetching tenant validity:", e);
      setIsValidTenant(false);
    }
  }

  useEffect(() => {
    getExpiryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadStuffs]);

  // Define primary and secondary navigation items
  const primaryNavItems = [
    { navItem: "Reads", to: "/reads", icon: Package2, color: "green" },
    { navItem: "Write", to: "/writes", icon: Rocket, color: "red" },
  ];

  const secondaryNavItems = [
    { navItem: "Deployer", to: "/deployer", icon: Rocket, color: "indigo" },
    { navItem: "Register", to: "/register", icon: UserPlus, color: "blue" },
    { navItem: "Prefund", to: "/topup", icon: DollarSign, color: "yellow" },
    { navItem: "Sub", to: "/renew", icon: Package2, color: "purple" },
  ];

  // Add "Receipt" for courier app
  if (app === "courier") {
    secondaryNavItems.push({
      navItem: "Receipt",
      to: "https://receipt-courier.vercel.app",
      icon: Package2,
      color: "teal",
      external: true, // Indicates external link
    });
  }

  // Function to handle sidebar toggle
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  // Function to handle navigation item click
  const handleItemClick = (item) => {
    if (!item.external) {
      navigate(item.to);
    } else {
      window.open(item.to, "_blank");
    }
    setSidebar(false); // Auto-collapse sidebar on selection
  };

  return (
    <header className="sticky top-0 z-50 flex h-16 items-center justify-between gap-4 border-b bg-background px-4 md:px-6 shadow-md">
      {/* Left side: Primary Navigation Items (Reads and Write) */}
      {(
          <div className="flex items-center gap-5 lg:gap-6 text-sm font-medium">
            {/* {window.location.pathname !== "/topup" &&  window.location.pathname !== "/deployer" && window.location.pathname !== "/login" && window.location.pathname !== "/login" && window.location.pathname !== "/" &&   window.location.pathname !== "/renew" &&   window.location.pathname !== "/register" && primaryNavItems.map((item, i) => {
              // Determine the color based on navItem
              let textColor = "text-muted-foreground";
              let hoverColor = "hover:text-foreground";

              if (item.color === "green") {
                textColor = "text-green-500";
                hoverColor = "hover:text-green-400";
              } else if (item.color === "red") {
                textColor = "text-red-500";
                hoverColor = "hover:text-red-400";
              }

              return (
                <NavLink
                  key={i}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? `font-bold ${textColor} ${hoverColor} cursor-pointer flex items-center gap-1`
                      : `${textColor} ${hoverColor} cursor-pointer flex items-center gap-1`
                  }
                >
                  <item.icon className="h-5 w-5" />
                  <span className="inline">{item.navItem}</span>
                </NavLink>
              );
            })} */}


             { is_valid_tenant &&  primaryNavItems.map((item, i) => {
              // Determine the color based on navItem
              let textColor = "text-muted-foreground";
              let hoverColor = "hover:text-foreground";

              if (item.color === "green") {
                textColor = "text-green-500";
                hoverColor = "hover:text-green-400";
              } else if (item.color === "red") {
                textColor = "text-red-500";
                hoverColor = "hover:text-red-400";
              }

              return (
                <NavLink
                  key={i}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? `font-bold ${textColor} ${hoverColor} cursor-pointer flex items-center gap-1`
                      : `${textColor} ${hoverColor} cursor-pointer flex items-center gap-1`
                  }
                >
                  <item.icon className="h-5 w-5" />
                  <span className="inline">{item.navItem}</span>
                </NavLink>
              );
            })}

            {/* Secondary Navigation Items (Desktop Only) */}
            <div className="hidden md:flex items-center gap-5 lg:gap-6 text-sm font-medium">
              {secondaryNavItems.map((item, i) => {
                let textColor = "text-muted-foreground";
                let hoverColor = "hover:text-foreground";

                if (item.color === "indigo") {
                  textColor = "text-indigo-500";
                  hoverColor = "hover:text-indigo-400";
                } else if (item.color === "blue") {
                  textColor = "text-blue-500";
                  hoverColor = "hover:text-blue-400";
                } else if (item.color === "yellow") {
                  textColor = "text-yellow-500";
                  hoverColor = "hover:text-yellow-400";
                } else if (item.color === "purple") {
                  textColor = "text-purple-500";
                  hoverColor = "hover:text-purple-400";
                } else if (item.color === "teal") {
                  textColor = "text-teal-500";
                  hoverColor = "hover:text-teal-400";
                }

                return (
                  <NavLink
                    key={i}
                    to={item.to}
                    target={item.external ? "_blank" : "_self"}
                    rel={item.external ? "noopener noreferrer" : undefined}
                    className={({ isActive }) =>
                      isActive
                        ? `font-bold ${textColor} ${hoverColor} cursor-pointer flex items-center gap-1`
                        : `${textColor} ${hoverColor} cursor-pointer flex items-center gap-1`
                    }
                  >
                    <item.icon className="h-5 w-5" />
                    <span className="inline">{item.navItem}</span>
                  </NavLink>
                );
              })}
            </div>
          </div>
        )}

      {/* Right side: Tenant Info and Mobile Menu */}
      <div className="flex items-center gap-4">
        {/* Tenant Info (Desktop Only) */}
        {is_valid_tenant && (
          <div className="hidden md:flex items-center gap-2 p-2 bg-gray-800 text-white rounded-lg shadow-md">
            <Package2 className="h-5 w-5 text-teal-400" />
            <div>
              <p className="text-sm">
                Tenant ID:{" "}
                <span className="font-semibold">
                  {localStorage.getItem("tenantId").length < 10
                    ? localStorage.getItem("tenantId")
                    : `${localStorage.getItem("tenantId").substring(0, 10)}...`}
                </span>
              </p>
              <p className="text-sm text-red-400">
                Expires in: <span className="font-semibold">{valid_till}</span>
              </p>
            </div>
          </div>
        )}

        {/* Mobile Sidebar Trigger */}
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/renew" && (
            <Sheet open={sidebar}>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="shrink-0 md:hidden"
                  onClick={toggleSidebar}
                  aria-label={sidebar ? "Close sidebar" : "Open sidebar"}
                >
                  {sidebar ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="w-64 bg-gray-900">
                <nav className="flex flex-col gap-6 text-lg font-medium">
                  <div className="flex items-center justify-between">
                    <NavLink
                      to="/"
                      className="flex items-center gap-2 text-lg font-semibold text-white hover:text-gray-300 transition-colors"
                      onClick={() => setSidebar(false)}
                    >
                      <Package2 className="h-6 w-6" />
                      <span>Dashboard</span>
                    </NavLink>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => setSidebar(false)}
                      aria-label="Close sidebar"
                    >
                      <X className="h-5 w-5" />
                    </Button>
                  </div>

                  {/* Secondary Navigation Items */}
                  {secondaryNavItems.map((item, i) => (
                    <NavLink
                      key={i}
                      to={item.to}
                      target={item.external ? "_blank" : "_self"}
                      rel={item.external ? "noopener noreferrer" : undefined}
                      onClick={() => handleItemClick(item)}
                      className={({ isActive }) =>
                        isActive
                          ? `font-bold block text-white bg-gray-700 rounded py-2 px-4 flex items-center gap-2`
                          : `block text-muted-foreground hover:bg-gray-700 hover:text-white rounded py-2 px-4 flex items-center gap-2`
                      }
                    >
                      <item.icon className="h-5 w-5" />
                      <span>{item.navItem}</span>
                    </NavLink>
                  ))}

                  {/* Tenant Info in Sidebar */}
                  {is_valid_tenant && (
                    <div className="mt-6 p-4 bg-gray-800 rounded-lg flex items-center gap-2">
                      <Package2 className="h-5 w-5 text-teal-400" />
                      <div>
                        <p className="text-sm text-gray-300">
                          TID:
                          <span className="font-semibold text-white ml-1">
                            {localStorage.getItem("tenantId").length < 7
                              ? localStorage.getItem("tenantId")
                              : `${localStorage.getItem("tenantId").substring(0, 7)}...`}
                          </span>
                        </p>
                        <p className="text-sm text-gray-300">
                          Expires in:
                          <span className="font-semibold text-red-400 ml-1">
                            {valid_till}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {/* A logout button  */}
                  <Button
                    variant="outline"
                    size="lg"
                    className="mt-6"
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                  >
                    Logout
                  </Button>
                </nav>
              </SheetContent>
            </Sheet>
          )}
      </div>

      {/* Tenant Info (Hidden on Mobile, already in sidebar) */}
    </header>
  );
};

export default Navbar;
