import React, { useEffect, useState } from "react";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from "../components/ui/select";
import { Button } from "../components/ui/button";
import def from "ajv/dist/vocabularies/discriminator";
import axios from "axios";
import { toast } from "react-toastify";

const FormPage = () => {
    const [apiKey, setApiKey] = useState('');
    const [pid, setPid] = useState('');
    const [licenseUrl, setLicenseUrl] = useState('example.com');
    const [serviceType, setServiceType] = useState('Courier');
    const [paymentMethod, setPaymentMethod] = useState('Balance');
    const [non_upfront, setIsNonUpfront] = useState(false);
    const [duration, setDuration] = useState(1);
    const [defaultPassword, setDefaultPassword] = useState('123456');
    const [tenantPrefix, setTenantPrefix] = useState('a_nice_prefix');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [balance, setBalance] = useState(0)


    const [licenseKey, setLicenseKey] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [service, setService] = useState('');
    const [valid_till, setValid_till] = useState('');
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'api_key':
                setApiKey(value);
                break;
            case 'default_password':
                setDefaultPassword(value);
                break;
            case 'tenant_prefix':
                setTenantPrefix(value);
                break;
            case 'pid':
                setPid(value);
                break;
            case 'license_url':
                setLicenseUrl(value);
                break;
            case 'duration':
                setDuration(Number(value));
                break;
            default:
                break;
        }
    };

    const handleSelectChange = (name, value) => {
        switch (name) {
            case 'service_type':
                setServiceType(value);
                break;
            case 'payment_method':
                setPaymentMethod(value);
                break;
            default:
                break;
        }
    };

    const handleCheckboxChange = (e) => {
        setIsNonUpfront(e.target.checked);
    };


    async function get_project_balance() {

        if (!apiKey) {
            return;
        }

        if (!pid) {
            return;
        }
        try {
            let api = `https://sentinel.solidhash.io/project/get/${pid}`;
            let project = await axios.get(api, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json',
                }
            })

            console.log(project.data)

            setBalance(project.data.balance)
            if (project.data.balance == 0.0) {
                setBalance("0.0")
            }
        } catch (e) {
            console.log(e)
            setBalance(0)
        }

    }

    useEffect(() => {
        get_project_balance();
    }, [pid, apiKey]);

    const handleSubmit = async (e) => {

        e.preventDefault();

        let api = "https://sentinel.solidhash.io/peripheral-license/license/create";

        try {
            let body = {
                pid: pid.trim(),
                license_url: licenseUrl.trim(),
                service_type: serviceType,
                payment_method: paymentMethod,
                non_upfront: non_upfront,
                duration: duration,
                default_password: defaultPassword,
                tenant_prefix: tenantPrefix,
            };

            let { data, status } = await axios.post(api, body, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json',
                }
            });



            console.log(data)
            if (status != 200) {
                toast.error(data.message);
                return;
            }
            setLicenseKey(data.key);
            setService(data.service);
            setValid_till(data.valid_till);
            setTenantId(data.tenant_id);
            setFormSubmitted(true);
        } catch (e) {
            console.log(e)
            toast.error(e.response.data.message)
        }

    };

    const handleBack = () => {
        setFormSubmitted(false);
    };

    return (
        <div className="w-[90%] mx-auto py-12 flex items-start justify-center">
            <div className="flex items-center justify-center max-w-2xl w-full border p-8 rounded shadow-lg bg-gray-900">
                <div className="mx-auto grid gap-6 w-full">
                    {formSubmitted ? (
                        <div className="text-center">
                            <h1 className="text-3xl font-bold text-white">Deployment Details</h1>
                            <p className="text-white mt-4">Admin Panel:</p>
                            <p
                                // onClick={() => {
                                //     window.open(`https://generic-admin.bigrouting.io`)

                                // }}
                                className="text-blue-500">{`https://generic-admin.bigrouting.io`}</p>
                            <p className="text-white mt-4">Username :</p>
                            <p className="text-blue-500">{tenant_id}</p>

                            <p className="text-white mt-4">Password:</p>
                            <p className="text-blue-500">{defaultPassword}</p>

                          

                            <p className="text-white mt-4">License Key:</p>
                            <p className="text-blue-500">{licenseKey}</p>

                            <p className="text-white mt-4">Service:</p>
                            <p className="text-blue-500">{service}</p>

                            {
                                serviceType.toLowerCase() === 'courier' && (
                                    <>
                                        <p className="text-white mt-4">A record :</p>
                                        <p className="text-blue-500">{"149.102.146.87"}</p>
                                    </>
                                )
                            }

                            {
                                serviceType.toLowerCase() === 'banking' && (
                                    <>
                                        <p className="text-white mt-4">A record :</p>
                                        <p className="text-blue-500">{"149.102.145.41"}</p>
                                    </>
                                )
                            }

                            <p className="text-white mt-4">Admin Panel:</p>
                            <p
                                onClick={() => {
                                    window.open(`https://generic-admin.bigrouting.io`)

                                }}
                                className="text-blue-500">{`https://generic-admin.bigrouting.io`}</p>
                            <Button onClick={handleBack} className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded">
                                Back
                            </Button>
                        </div>
                    ) : (
                        <>
                            <h1 className="text-3xl font-bold text-white">Deployer</h1>

                            <form className="grid gap-4" onSubmit={handleSubmit}>


                                <div className="grid gap-2">
                                    <Label htmlFor="pid" className="text-white">PID {balance ? `Balance : ${balance} USD` : ``}</Label>
                                    <Input
                                        id="pid"
                                        name="pid"
                                        type="text"
                                        placeholder="Enter PID"
                                        value={pid}
                                        onChange={handleInputChange}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <Label htmlFor="api_key" className="text-white">API Key</Label>
                                    <Input
                                        id="api_key"
                                        name="api_key"
                                        type="password"
                                        placeholder="Enter API Key"
                                        value={apiKey}
                                        onChange={handleInputChange}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>

                                <div className="grid gap-2">
                                    <Label htmlFor="api_key" className="text-white">Default Admin Password</Label>
                                    <Input
                                        id="default_password"
                                        name="default_password"
                                        type="text"
                                        placeholder="Enter Default Password"
                                        value={defaultPassword}
                                        onChange={handleInputChange}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <Label htmlFor="license_url" className="text-white"> Domain Name</Label>
                                    <Input
                                        id="license_url"
                                        name="license_url"
                                        type="text"
                                        placeholder="Domain Name"
                                        value={licenseUrl}
                                        onChange={handleInputChange}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <div className="grid gap-2">
                                    <Label htmlFor="service_type" className="text-white">Service Type</Label>
                                    <Select
                                        value={serviceType}
                                        onValueChange={(value) => handleSelectChange('service_type', value)}
                                    >
                                        <SelectTrigger className="bg-gray-800 text-white">
                                            <SelectValue placeholder="Select Service Type" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-gray-800 text-white">
                                            {['Courier', "Banking", "Broker"].map((type) => (
                                                <SelectItem value={type} key={type}>
                                                    {type}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                                {/* <div className="grid gap-2">
                                    <Label htmlFor="payment_method" className="text-white">Payment Method</Label>
                                    <Select
                                        value={paymentMethod}
                                        onValueChange={(value) => handleSelectChange('payment_method', value)}
                                    >
                                        <SelectTrigger className="bg-gray-800 text-white">
                                            <SelectValue placeholder="Select Payment Method" />
                                        </SelectTrigger>
                                        <SelectContent className="bg-gray-800 text-white">
                                            {['Balance'].map((method) => (
                                                <SelectItem value={method} key={method}>
                                                    {method}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div> */}
                                <div className="flex items-center gap-2">
                                    <input
                                        id="is_non_upfront"
                                        name="is_non_upfront"
                                        type="checkbox"
                                        disabled={false}
                                        checked={non_upfront}
                                        onChange={handleCheckboxChange}
                                        className="form-checkbox bg-gray-800 border-gray-600 text-white"
                                    />
                                    <Label htmlFor="is_self_hosted" className="text-white">Installmental Payment</Label>
                                </div>
                                {/* <div className="grid gap-2">
                                    <Label htmlFor="duration" className="text-white">Duration (Months)</Label>
                                    <Input
                                        id="duration"
                                        name="duration"
                                        type="number"
                                        placeholder="Enter Duration"
                                        value={duration}
                                        onChange={handleInputChange}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div> */}
                                <div className="grid gap-2">
                                    <Label htmlFor="tenant_prefix" className="text-white">Discount Code</Label>
                                    <Input
                                        id="discount"
                                        name="discount"
                                        type="text"
                                        placeholder="Enter discount"
                                        disabled={true}
                                        value={""}
                                        onChange={handleInputChange}
                                        className="bg-gray-800 text-white placeholder-gray-500"
                                        required
                                    />
                                </div>
                                <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded">
                                    Pay {non_upfront ? "11.00" : "100.00"} USD
                                </Button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormPage;
